import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import RestaurantCard from "./components/RestaurantCard";

function HomeScreen() {
  const [restaurants, setRestaurants] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!restaurants) {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/restaurant`
          );
          if (data) {
            setRestaurants(data);
            setLoading(false);
          }
        } catch (error) {
          toast.error("Error retrieving restaurants");
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [restaurants]);
  return (
    <Layout>
      {!restaurants && loading && (
        <div className='flex flex-col w-full items-center'>
          <LoadingSpinner />
          <p className='mt-2'>Loading Restaurants...</p>
        </div>
      )}
      {restaurants && !loading && (
        <div className='flex flex-col p-2'>
          <h3 className='text-lg font-bold'>Restaurants</h3>
          <div className='flex flex-wrap w-full items-center justify-center lg:justify-start'>
            {restaurants.map((restaurant) => (
              <RestaurantCard restaurant={restaurant} />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default HomeScreen;
