import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  markNotAuthenticated,
  signInUserToken,
} from "./redux/actions/authActions";
import LoginScreen from "./routes/Login";
import RegisterScreen from "./routes/Register";
import ForgotPasswordScreen from "./routes/ForgotPassword";
import HomeScreen from "./routes/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestaurantScreen from "./routes/Restaurant";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import LandingPage from "./routes/LandingPage";
import RatingsScreen from "./routes/Ratings";

function App() {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.signedIn === null) {
      const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
      unsubscribe();
    }
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      {auth.signedIn === null ? (
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />

          <p className='mt-4'>Welcome to Rated Dishes</p>
        </div>
      ) : auth.signedIn ? (
        // authenticated routes
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path='/restaurant'>
            <Route path=':restaurantId' element={<RestaurantScreen />} />
          </Route>
          <Route path='/ratings' element={<RatingsScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      ) : (
        // unauthenticated routes
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}

      <ToastContainer autoClose={2000} position='bottom-left' />
    </BrowserRouter>
  );
}

export default App;
