import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import RatingsTable from "./components/RatingsTable";

function RatingsScreen() {
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (ratings.length === 0) {
        setLoading(true);
        firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/rating`,
              {
                headers: {
                  Authorization: "Bearer " + idToken,
                },
              }
            );
            console.log(data);
            setRatings(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error("Error retrieving ratings");
          }
        });
      }
    }
    fetchData();
  }, [ratings]);
  return (
    <Layout>
      {loading && (
        <div className='flex flex-col mt-10 items-center'>
          <LoadingSpinner />
          <p className='mt-2'>Loading Ratings...</p>
        </div>
      )}
      {!loading && (
        <div className='flex flex-col p-2'>
          <RatingsTable ratings={ratings} />
        </div>
      )}
    </Layout>
  );
}

export default RatingsScreen;
