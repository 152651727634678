import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import DishCard from "./components/DishCard";
import DishSection from "./components/DishSection";

function RestaurantScreen() {
  const [restaurantToView, setRestaurantToView] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { restaurantId } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (!restaurantToView) {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/restaurant/restaurantId`,
            {
              params: {
                restaurantId,
              },
            }
          );
          if (data) {
            setRestaurantToView(data);
            setDishes(data.restaurantDishes);
          } else {
            toast.error("No campaign found");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error retrieving restaurant details");
        }
      }
    }
    fetchData();
  }, [restaurantToView, restaurantId]);

  return (
    <Layout>
      {!restaurantToView && loading && (
        <div className='flex flex-col mt-10 items-center'>
          <LoadingSpinner />
          <p className='mt-2'>Loading Restaurant...</p>
        </div>
      )}
      {restaurantToView && !loading && (
        <div className='flex flex-col p-2'>
          <h3 className='text-lg font-bold'>{restaurantToView.name}</h3>
          <DishSection
            category='Starters'
            dishes={dishes.filter((dish) => dish.category === "Starter")}
          />
          <DishSection
            category='Small Plates'
            dishes={dishes.filter((dish) => dish.category === "Small Plate")}
          />
          <DishSection
            category='Sharing Platters'
            dishes={dishes.filter(
              (dish) => dish.category === "Sharing Platter"
            )}
          />
          <DishSection
            category='Breakfast'
            dishes={dishes.filter((dish) => dish.category === "Breakfast")}
          />
          <DishSection
            category='Mains'
            dishes={dishes.filter((dish) => dish.category === "Main")}
          />
          <DishSection
            category='Desserts'
            dishes={dishes.filter((dish) => dish.category === "Dessert")}
          />
        </div>
      )}
    </Layout>
  );
}

export default RestaurantScreen;
