import React from "react";

function RatingField({ label, value, onChange }) {
  return (
    <div className='flex flex-col mt-2 mb-4 w-fit'>
      <label className='mb-2'>{label}</label>
      <select
        value={value}
        className='p-2 h-10 border rounded-lg w-fit'
        onChange={onChange}
      >
        <option value={0} disabled>
          -
        </option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </select>
    </div>
  );
}

export default RatingField;
