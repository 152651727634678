import React from "react";
import DishCard from "./DishCard";

function DishSection({ category, dishes }) {
  return (
    <div className='flex flex-col'>
      {dishes.length > 0 && (
        <>
          <h3 className='text-lg font-semibold mx-4 mt-4'>{category}</h3>
          <div className='flex flex-wrap'>
            {dishes?.map((dish) => (
              <DishCard dish={dish} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DishSection;
