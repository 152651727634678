import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout";
import LandingPageBackground from "../../LandingPageBackground.jpg";

function LandingPage() {
  return (
    <Layout maxScreen={true}>
      <img src={LandingPageBackground} className='w-full h-full' />
      <div className='lg:absolute lg:top-10 lg:left-10 flex flex-col w-full lg:w-7/12 mx-auto lg:opacity-90 bg-white shadow-lg py-4 px-8 lg:rounded-lg'>
        <p className='text-center font-semibold'>
          Welcome to Rated Dishes, the ultimate destination for food lovers
          across the United Kingdom!
        </p>
        <p className='my-4'>
          We understand that one of the most exciting aspects of dining out is
          discovering new, delicious dishes to tantalize your taste buds. That's
          why we've created a platform that allows you to share and rate your
          favourite dishes from restaurants all over the UK.
        </p>
        <p className='my-4'>
          Our website is designed to be intuitive and easy to use, with a
          comprehensive database of restaurants and their menus. You can search
          for a specific dish or browse by cuisine, location, or restaurant to
          find new and exciting culinary experiences. Once you've found a dish
          that catches your eye, you can rate it based on your personal
          preferences and add your own comments to help others make informed
          decisions.
        </p>
        <p className='my-4'>
          We believe that everyone has their own unique tastes and preferences
          when it comes to food, and we're passionate about providing a platform
          that allows you to share your experiences and discover new culinary
          delights. So why wait? Join our community today and start exploring
          the best dishes that the UK has to offer!
        </p>
        <p className='my-4'>
          Rate your plate and help fellow foodies find their perfect taste!
        </p>
      </div>
      <div className='lg:absolute lg:top-48 lg:right-32 bg-white flex lg:flex-col justify-center lg:justify-start p-4 rounded-lg w-full lg:w-2/12'>
        <Link
          to='/register'
          className='text-center text-xl text-white px-4 py-2 bg-slate-500 rounded-lg w-full lg:w-7/12 mx-auto my-4 lg:my-4'
        >
          Join Now
        </Link>
      </div>
    </Layout>
  );
}

export default LandingPage;
