import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/ui/Table";
import { dishScoreCalculator } from "../../../utils/dishScoreCalculator";

function RatingsTable({ ratings }) {
  const columns = [
    {
      title: "Restaurant",
    },
    {
      title: "Dish",
    },
    {
      title: "Flavour",
    },
    {
      title: "Portion",
    },
    {
      title: "Score",
    },
    {
      title: "Comments",
      className: "text-left px-4",
    },
  ];

  console.log("Ratings: ", ratings);

  //(C2×0.8)+(D2×0.2)

  const rows = ratings.map((rating) => ({
    fields: [
      {
        value: (
          <Link to={`/restaurant/${rating.dish.restaurant.id}`}>
            {rating.dish.restaurant.name}
          </Link>
        ),
      },
      { value: rating.dish.name },
      { value: rating.flavour },
      { value: rating.portion },
      {
        value: dishScoreCalculator(rating.flavour, rating.portion)?.toFixed(1),
      },
      {
        value: rating.comments,
        className: "text-left px-4",
      },
    ],
  }));

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg overflow-auto'>
      <Table columns={columns} rows={rows} />
    </div>
  );
}

export default RatingsTable;
