import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import RatingField from "../../../components/ui/RatingField";
import { firebaseAuth } from "../../../firebase";

function RatingModal({ dish, setShowRatingModal }) {
  const [loading, setLoading] = useState(false);
  const [newRating, setNewRating] = useState({
    flavour: 0,
    portion: 0,
    comments: "",
  });

  const saveRatingHandler = () => {
    setLoading(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}/rating`,
          {
            dish,
            newRating,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data) {
          setLoading(false);
          setShowRatingModal(false);
          toast.success("Rating saved");
        } else {
          toast.error("Error saving rating");
          setLoading(false);
        }
      } catch (error) {
        toast.error("Error saving rating");
        setLoading(false);
      }
    });
  };

  const onCloseHandler = () => {
    setShowRatingModal(false);
  };
  return (
    <ModalTemplate title='Add Rating' onClose={onCloseHandler}>
      <div className='flex flex-col p-4'>
        <p className='text-lg font-semibold'>Add rating for {dish.name}</p>
        <div className='flex justify-around'>
          <RatingField
            label='Flavour'
            value={newRating.flavour}
            onChange={(e) =>
              setNewRating({ ...newRating, flavour: e.target.value })
            }
          />
          <RatingField
            label='Portion'
            value={newRating.portion}
            onChange={(e) =>
              setNewRating({ ...newRating, portion: e.target.value })
            }
          />
        </div>
        <label className='mb-2'>Comments</label>
        <textarea
          className='p-2 border rounded-lg'
          rows={4}
          value={newRating.comments}
          onChange={(e) =>
            setNewRating({ ...newRating, comments: e.target.value })
          }
        />
        <div className='flex mx-auto space-x-8 mt-4'>
          <button
            className='bg-blue-400 text-white w-20 py-2 rounded-lg shadow-lg'
            disabled={loading}
            onClick={() => saveRatingHandler()}
          >
            Save
          </button>
          <button
            className='bg-slate-400 disabled:bg-slate-200 text-white w-20 py-2 rounded-md'
            disabled={loading}
            onClick={() => onCloseHandler()}
          >
            Cancel
          </button>
        </div>
      </div>
    </ModalTemplate>
  );
}

export default RatingModal;
