import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signOutUser } from "../../redux/actions/authActions";
import NavButton from "./components/NavButton";

function Header() {
  const user = useSelector((state) => state.auth.signedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className='flex items-center justify-between p-4 bg-slate-500 text-white'>
      <Link className='flex flex-col text-xl' to='/'>
        <p className='text-xl'>Rated Dishes</p>
      </Link>
      {!user && (
        <div className='flex items-center space-x-4'>
          <Link
            className='bg-blue-400 text-white border-2 border-blue-400 text-center w-20 py-2 rounded-lg shadow-lg'
            to='/login'
          >
            Sign In
          </Link>

          <Link
            className='text-center w-20 py-2 rounded-lg shadow-lg border-white border-2'
            to='/register'
          >
            Join
          </Link>
        </div>
      )}
      {user && (
        <div>
          <NavButton label='My Ratings' onClick={() => navigate("/ratings")} />
        </div>
      )}
      {user && (
        <button
          className='text-center w-20 py-2 rounded-lg shadow-lg border-white border-2'
          onClick={() => dispatch(signOutUser())}
        >
          Sign Out
        </button>
      )}
    </div>
  );
}

export default Header;
