import React from "react";

function Footer() {
  return (
    <div className='flex p-4 bg-slate-600 text-white justify-center'>
      <p>Rated Dishes 2023</p>
    </div>
  );
}

export default Footer;
