import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RatingModal from "./RatingModal";

function DishCard({ dish }) {
  const [showRatingModal, setShowRatingModal] = useState(false);
  const user = useSelector((state) => state.auth.signedIn);
  return (
    <div className='flex flex-col justify-between shadow-lg w-11/12 lg:w-2/12 h-52 lg:h-60 p-4 mx-4 my-4'>
      <div className='flex flex-col'>
        <p className='font-semibold mb-1'>{dish.name}</p>
        <div className='flex flex-wrap'>
          {dish.tags?.map((tag) => (
            <p className='px-2 bg-blue-200 rounded-md mr-2 mb-2 text-xs'>
              {tag}
            </p>
          ))}
        </div>
      </div>
      <div>
        {dish?.dishRatings?.map((rating) => (
          <div className='flex justify-between'>
            <p>Flavour: {rating.flavour}</p>
            <p>Portion: {rating.portion}</p>
          </div>
        ))}
      </div>
      {!user && (
        <Link
          to='/login'
          className='bg-blue-400 text-white text-center p-2 rounded-lg shadow-lg text-sm'
        >
          Sign in to rate and view ratings
        </Link>
      )}
      {user && (
        <button
          className='bg-blue-400 text-white p-2 rounded-lg shadow-lg'
          onClick={() => setShowRatingModal(true)}
        >
          Add Rating
        </button>
      )}
      {showRatingModal && (
        <RatingModal dish={dish} setShowRatingModal={setShowRatingModal} />
      )}
    </div>
  );
}

export default DishCard;
