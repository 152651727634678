import React from "react";

function NavButton({ label, onClick }) {
  return (
    <button
      className='text-center w-28 py-2 rounded-lg shadow-lg border-white border-2'
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default NavButton;
