import React from "react";
import { Link } from "react-router-dom";

function RestaurantCard({ restaurant }) {
  return (
    <Link
      to={`/restaurant/${restaurant.id}`}
      className='flex flex-col shadow-lg w-11/12 lg:w-2/12 h-40 p-4 mx-4 my-4'
    >
      <p>{restaurant.name}</p>
    </Link>
  );
}

export default RestaurantCard;
