import React from "react";

function Table({ columns, rows }) {
  return (
    <table className='table-auto border w-full mx-auto'>
      <thead>
        <tr className='bg-slate-200 text-youthsGrey text-xs'>
          <th>#</th>
          {columns.map((column, index) => (
            <th key={index} className={column.className}>
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Below checks users is not null and then maps each user returned to the table */}
        {rows?.map((row, index) => (
          <tr
            key={index}
            className={
              index % 2
                ? "bg-slate-100 text-center text-xs h-10"
                : "bg-slate-300 text-center text-xs h-10"
            }
          >
            <td>{index + 1}</td>
            {row.fields.map((field, index) => (
              <td key={index} className={field.className}>
                {field.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
