import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";

function Layout({ children, maxScreen }) {
  return (
    <div
      className={`flex flex-col w-full ${
        maxScreen ? "xl:max-h-screen overflow-clip" : "min-h-screen"
      }`}
    >
      <Header />
      <div className='relative flex flex-col grow max-h-full overflow-clip w-full bg-slate-50'>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
